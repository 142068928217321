import React, { Component, useState, useEffect } from "react";
import moment from "moment";

import { FilterOutlined, ReloadOutlined, SyncOutlined } from "@ant-design/icons";

import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";
import { Table, Checkbox, Radio, Input, Col, Row } from "antd";
import { DatePicker, Space, Popover, Tag, Tooltip, Button } from 'antd';

import CompanySelect from "../shared/CompanySelect";
import TransactionTypes from "../shared/TransactionTypes";
import TransactionUnlinkBtn from "./TransactionUnlinkBtn";
import TransactionDeleteBtn from "./TransactionDeleteBtn";
import TransactionAssignForm from "./TransactionAssignForm";
import Transaction2ConvBtn from "./Transaction2ConvBtn";
import { getUserTenant } from "../../auth/authHelper";

import Ibans from "../shared/Ibans";
import Counterparts from "../shared/Counterparts";

import FormBtn from "../shared/FormBtn";

import { get_currency_list_by_tenant }  from "../../tenants";

const { RangePicker } = DatePicker;

const LINK_TYPE = Object.freeze({
  BOTH:     0,
  LINKED:   1,
  UNLINKED: 2,
});

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_LINK_TYPE = LINK_TYPE.UNLINKED;

const DEFAULT_FILTER = {desc: '', linked: DEFAULT_LINK_TYPE, hide_generated: false, hide_ignored: false};
const DEFAULT_TABLE_PARAMS = {
    sorter: { field: "created_at", order: "descend" },
    pagination: {
      current: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    }};

///////////////////////////////////////////////////////////////////////////////
const TransactionsTable = (props) => {
  var filteredData;
  const { auth, tab } = useStoreon("auth", "tab");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState({USD:0, EUR:0, CNY:0});
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [prevFilter, setPrevFilter] = useState(filter);
  const [tableParams, setTableParams] = useState(DEFAULT_TABLE_PARAMS);

  ////////////////////////////////////////////////////////////////////////////////////

debugger;
                          
  const currency_filter = get_currency_list_by_tenant(getUserTenant()).map((c) => ({text: c, value: c}));

const columns_1 = [
  {
    title: "Date",
    dataIndex: "created_at",
    render: date => <>{moment(date).format("DD.MM.YYYY")} </>,
    sortOrder: tableParams.sorter?.field === 'created_at' && tableParams.sorter?.order,
    sorter: (a, b) => {
      let A = a.created_at;
      let B = b.created_at;
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    },
    _defaultSortOrder: "descend",
    width: "1%",
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => 
     <RangePicker 
      value={tableParams.filters?.["created_at"][0] || []}
      allowClear={true}
      onChange={(value) => {
//        setTableParams((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "created_at": value?[value]:null}}));
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: tableParams.filters?.["created_at"] || [],
    onFilter: (value, record) => { 
    return moment(record.created_at).isBetween(value[0]._d, value[1]._d, 'day', '[]')
    }
  },
/*
       values.range[0] = values.range[0].startOf('day');
        values.range[1] = values.range[1].endOf('day');
        console.log("filter.range:", values.range);
*/
  {
    dataIndex: "created_at",
    render: date => <>{moment(date).format("HH:mm:ss")} </>,
    width: "1%",
  },
];

const columns_type = [
  {
    title: "Type",
    dataIndex: "type_transaction",
    width: "1%",
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <TransactionTypes
      value={tableParams.filters?.["type_transaction"] || null}
      mode="filter"
      allowClear={true}
      style={{width: "300px"}}
      onChange={(value) => {
        //setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "supplier.name": value?[value]:null}}));
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: tableParams.filters?.["type_transaction"] || null,
    onFilter: (value, record) => (!record.type_transaction && value === '-')||(record.type_transaction?.indexOf(value) === 0),
  },
];

const columns_2 = [
  {
    title: "Counterpart",
    dataIndex: "counterpart_calc",
    sortOrder: tableParams.sorter?.field === 'counterpart_calc' && tableParams.sorter?.order,
    sorter: (a, b) => {
      let A = a.counterpart_calc;
      let B = b.counterpart_calc;
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    },
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <Counterparts
      value={tableParams.filters?.["counterpart_calc"] || null}
      allowClear={true}
      showSearch
      style={{width: "300px"}}
      onChange={(value) => {
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: tableParams.filters?.["counterpart_calc"] || null,
    onFilter: (value, record) => { 
      //alert(JSON.stringify(record.counterpart_calc))
      return record.counterpart_calc === value
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    align: 'right',
    width: '1%',
    sortOrder: tableParams.sorter?.field === 'amount' && tableParams.sorter?.order,
    sorter: (a, b) => {
      let A = Number(a.amount);
      let B = Number(b.amount);
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    },
    render: (item, rec) => <Tooltip placement="right" mouseLeaveDelay="0" title={rec.type_transaction}>{item.toFixed(2)}</Tooltip>,
  },
  {
    title: "Currency",
    dataIndex: "currency",
    width: "1%",
    sorter: (a, b) => {
      let A = a.currency;
      let B = b.currency;
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    },
    filters: currency_filter,
    filterMultiple: false,
    filteredValue: tableParams.filters?.["currency"] || null,
    onFilter: (value, record) => record.currency.indexOf(value) === 0,
  },
  {
    title: "Description",
    dataIndex: "desc_calc",
    render: (item, rec) => <>{item}{item!=rec.description &&
    <Popover style={{"max-width": 300}} content={rec.description} trigger="click">
      <Tag.CheckableTag style={{"leftMargin": 10}}>[raw]</Tag.CheckableTag>
    </Popover>}
    </>,
  },
  {
    title: "IBAN",
    dataIndex: "iban_calc",
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <Ibans
      value={tableParams.filters?.["iban_calc"] || null}
      allowClear={true}
      showSearch
      style={{width: "300px"}}
      onChange={(value) => {
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: tableParams.filters?.["iban_calc"] || null,
    onFilter: (value, record) => { 
      //alert(JSON.stringify(record.iban_calc))
      return record.iban_calc === value
    },
  },
  {
    title: "Initiator",
    dataIndex: ["initiator_company", "name"],
    sortOrder: tableParams.sorter?.field.toString() === ["initiator_company", "name"].toString() && tableParams.sorter?.order,
    sorter: filter.linked != LINK_TYPE.UNLINKED ? (a, b) => {
      let A = a.initiator_company?.name;
      let B = b.initiator_company?.name;
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    } : undefined,

    filterDropdown: filter.linked != LINK_TYPE.UNLINKED ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <CompanySelect
      value={tableParams.filters?.["initiator_company.name"] || null}
      role="transaction_initiator"
      my_company_id={auth.company_id}
      allowClear={true}
      style={{width: "300px"}}
      onChange={(value) => {
        //setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "supplier.name": value?[value]:null}}));
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    /> : undefined,

    filteredValue: tableParams.filters?.["initiator_company.name"] || null,
    onFilter: (value, record) => { 
      //alert(JSON.stringify(record.initiator_company))
      //alert(JSON.stringify(value))
      //return record.initiator_company?.id?.indexOf(value) === 0
      return record.initiator_company?.id === value
    },
  },
  {
    title: "Comments",
    dataIndex: "comments"
  },
  {
    title: "Linked",
    dataIndex: "linked_user",
  },
/* disabled 2023.02.15 
  {
    title: "Beneficiary",
    dataIndex: ["beneficiary_company", "name"]
  },
  {
    title: "Remitter",
    dataIndex: ["remitter_company", "name"]
  },
  {
    title: "Reference",
    dataIndex: "internal_reference",
    render: date => <>{date?(date.substring(0,15)+(date.length>15?'...':'')):''}</>,
  },
  {
    title: "Id",
    dataIndex: "id",
    render: date => <>{date.split('-')[0]+'...'} </>
  },
*/
  {
    title: "",
    render: (record) => !record.type_transaction?(
      <div style={{ float: "right", whiteSpace: "nowrap" }}>
          <FormBtn
            component="transaction_assign"
            action="edit"
            icon_only={true}
            hint="Assign this transaction"
            id={record.id} 
          />
        <Transaction2ConvBtn
          id={record.id}
          link_id={record.link_id}
          type_transaction={record.type_transaction}
        /> 
      </div>
    ):
    ((['ADM'].includes(record.type_transaction))?(
      <div style={{ float: "right" }}>
        <TransactionDeleteBtn
          id={record.id}
          link_id={record.link_id}
          type_transaction={record.type_transaction}
          />
      </div>
    ):
    ((['INC', 'OUT', 'IGN', 'CNV'].includes(record.type_transaction))?(
      <>
      <div style={{ float: "right", whiteSpace: "nowrap" }}>
        <TransactionUnlinkBtn
          id={record.id}
          link_id={record.link_id}
          type_transaction={record.type_transaction}
          />
      </div>
      </>
    ):<></>
    )),
  },

/*
  {
    title: "",
    render: (record) => (['ADM', 'INC', 'OUT'].includes(record.type_transaction))?(
      <div style={{ float: "right" }}>
        <TransactionDeleteBtn id={record.id} link_id={record.link_id} type="danger" />
      </div>
    ):<></>,
  }
*/
];
  ////////////////////////////////////////////////////////////////////////////////////
 const reCalcAmount = (extra) => {

   const filteredRowKeys = extra.currentDataSource.map((r) => r.id);
   const intersection = selectedRowKeys.filter(x => filteredRowKeys.includes(x));

   setSelectedRowKeys(intersection);

   const selected_data = filteredData.filter((t) => intersection.find((id) => t.id == id));
   const selected_amount = selected_data.reduce((t, a) => { t[a.currency] += a.amount; return(t) }, {USD:0, EUR:0, CNY:0});

   setSelectedAmount(selected_amount);

 }

  useEffect(() => {

    if (data?.p_transactions && filteredData && data.p_transactions != filteredData && filter != prevFilter) {
      reCalcAmount({currentDataSource: filteredData});
/*
      const filteredRowKeys = filteredData.map((r) => r.id);
      const intersection = selectedRowKeys.filter(x => filteredRowKeys.includes(x));

      const selected_data = filteredData.filter((t) => intersection.find((id) => t.id == id));
      const selected_amount = selected_data.reduce((t, a) => { t[a.currency] += a.amount; return(t) }, {USD:0, EUR:0, CNY:0});

      setSelectedAmount(selected_amount);
*/
    }
  }, [filter]);

 const handleTableChange = (pagination, filters, sorter, extra) => {
    //alert(JSON.stringify(filters));
    setTableParams({
      pagination,
      filters,
      sorter,
    });
//      ...sorter,

debugger;

    if (extra.action === "filter") {
      reCalcAmount(extra);
    }
 };

  let query;
  switch (props.type) {
   case 'INC': query = GET_TRANSACTIONS_INC; break;
   case 'OUT': query = GET_TRANSACTIONS_OUT; break;
   default   : query = GET_TRANSACTIONS;
  }

  const { loading, error, data, refetch } = useQuery(query, {});
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  // filters: begin ///////////////////////////////////////////////////////////////////////
  filteredData = (filter.desc||filter.linked != LINK_TYPE.BOTH||filter.hide_generated||filter.hide_ignored) ? 
     data.p_transactions.filter((t) => {
       var is_desc = filter.desc?t.desc_calc.toLowerCase().includes(filter.desc.toLowerCase()):true;

       var is_link = LINK_TYPE.LINKED === filter.linked ? (t.initiator_id !== null) :
                     LINK_TYPE.UNLINKED === filter.linked ? (t.initiator_id === null): true;

       var is_hide = !(filter.hide_generated & ["COM","AEX","INI"].includes(t.type_transaction));
       var is_hide_ign = !(filter.hide_ignored && t.type_transaction === "IGN");

       return is_desc & is_link & is_hide & is_hide_ign;
     })
      :
     data.p_transactions;
  // filters: end ///////////////////////////////////////////////////////////////////////


  // selection: begin ///////////////////////////////////////////////////////////////////////
  const onSelectChange = (newSelectedRowKeys) => {

    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);

    var selected_data = filteredData.filter((t) => newSelectedRowKeys.find((id) => t.id == id));
    var selected_amount = selected_data.reduce((t, a) => { t[a.currency] += a.amount; return(t) }, {USD:0, EUR:0, CNY:0});
    setSelectedAmount(selected_amount);
  };

  ////////////////
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
        // Table.SELECTION_ALL,Table.SELECTION_INVERT, Table.SELECTION_NONE,
        {
          key: 'data-all',
          text: 'Select All Data',
          onSelect: changableRowKeys => {
            onSelectChange(filteredData.map((a) => a.id));
          },
        },
        {
          key: 'data-none',
          text: 'Clear All Data',
          onSelect: changableRowKeys => {
            onSelectChange([]);
            //setSelectedRowKeys([]);
          },
        },
    ],
    hideDefaultSelections: true,
  };
  // selection: end ///////////////////////////////////////////////////////////////////

  // tools: begin /////////////////////////////////////////////////////////////////////
  const handleToolsChange = (params) => {
    setPrevFilter(filter);
    setFilter(params);
  }
  // tools: end ///////////////////////////////////////////////////////////////////////

 // [...[1,2], ...["1", "2"].includes("3")?[5]:[6], ...[8,9]]
 const columns = [...columns_1, ...["INC", "OUT"].includes(props.type)?[]:columns_type, ...columns_2];

 return (
    <>
      <Space size="middle">
          <Space size="small">
           <FormBtn size="small" component="transaction" action="add" hint="Add manual Transfer"/>           
           <Tooltip placement="left" mouseLeaveDelay="0" title="Reset filters" >
             <Button size="small" type="link" icon={<FilterOutlined/>} onClick={(e) => { setFilter(DEFAULT_FILTER); setTableParams(DEFAULT_TABLE_PARAMS); }} >Reset</Button>
           </Tooltip>
           <Tooltip placement="left" mouseLeaveDelay="0" title="Reload transactions data" >
             <Button size="small" type="link" icon={<SyncOutlined />} onClick={(e) => { refetch(); }} >Refetch</Button>
           </Tooltip>
          </Space>

          <Space size="small" style={{whiteSpace: "nowrap" }}>
            {["INC", "OUT"].includes(props.type)?<b>{props.type}: </b>:<></>}
             <Radio.Group 
               onChange={(e) => handleToolsChange({ ...filter, linked: e.target.value }) } 
               value={filter.linked} 
               buttonStyle="solid"
               size="small"
             >
               <Radio.Button value={LINK_TYPE.BOTH}>Both</Radio.Button>
               <Radio.Button value={LINK_TYPE.LINKED}>linked</Radio.Button>
               <Radio.Button value={LINK_TYPE.UNLINKED}>not linked</Radio.Button>
             </Radio.Group>
          </Space>

<Space size="small" align="baseline" direction="horizontal" style={{whiteSpace: "nowrap" }}>
Hide: 
           <Checkbox onChange={(e) => handleToolsChange({ ...filter, hide_ignored: e.target.checked }) } 
             checked={filter.hide_ignored}>ignored</Checkbox>
        {(props.type == "ALL") &&
           <Checkbox onChange={(e) => handleToolsChange({ ...filter, hide_generated: e.target.checked }) } 
             checked={filter.hide_generated}>generated</Checkbox>
        }
</Space>

          <Space size="small">
          <Input
            placeholder="search in description ..."
            value={filter.desc}
            onChange={(e) => handleToolsChange({...filter, desc: e.target.value}) }
          />
         <>EUR: <b>{(selectedAmount.EUR||0).toFixed(2)}</b> USD: <b>{(selectedAmount.USD||0).toFixed(2)}</b></>
        </Space>
</Space>
          <Table
            _rowKey="id"
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
            pagination={{showSizeChanger: true,}}
            columns={columns}
            dataSource={filteredData}
            size="small"
            onChange={handleTableChange}
            bordered
          />
    </>
  );
};

//            _pagination={/*filteredData.length > PAGE_SIZE ? {pageSize: PAGE_SIZE} : false*/}

export const FRAGMENT_TRANSACTIONS = gql`
fragment p_transactions_fields on p_transactions {
    id
    account_id
    amount
    currency
    description
    desc_calc
    internal_reference
    type_transaction
    type_transaction_domain {
      comment
    }
    created_at
    initiator_id
    initiator_company {
     id
     name
    }
    beneficiary_company {
     id
     name
    }
    remitter_company {
     id
     name
    }
    link_id
    linked_user
    linked_at
    synchronized_at
    comments
    remittance_information
    counterpart_name
    counterpart_reference
    counterpart_calc
    iban_calc
}
`;

// Kalin V, [21.02.2023 14:11]
/*
inc_not_liked - amount>0 and type_transaction is null and initiator_id is null
out_not_liked - amount<0 and type_transaction is null and initiator_id is null

--�� � ������� �� ��� ���� ������ ADM � COM
--type_transaction is nul

inc_liked type_transaction=INC and initiator is not null
out_liked -  type_transaction=OUT and initiator is not null


inc_not_liked - amount>0 and type_transaction is null and initiator_id is null
inc_liked     - amount>0 and type_transaction=INC     and initiator is not null

out_not_liked - amount<0 and type_transaction is null and initiator_id is null
out_liked     - amount<0 and type_transaction=OUT     and initiator is not null

*/

const GET_TRANSACTIONS = gql`
  ${FRAGMENT_TRANSACTIONS}
query Transactions {
  p_transactions(order_by: {created_at: desc}) {
    ...p_transactions_fields
  }
}
`;

const GET_TRANSACTIONS_INC = gql`
  ${FRAGMENT_TRANSACTIONS}
query Transactions {
  p_transactions(
   where: {_and: [ 
      {_or: [
        {type_transaction: {_eq: "INC"}},
        {type_transaction: {_is_null: true}},
      ]},
     {amount: {_gt: 0}}
   ]},
   order_by: {created_at: desc}
  ) {
    ...p_transactions_fields
  }
}
`;

const GET_TRANSACTIONS_OUT = gql`
  ${FRAGMENT_TRANSACTIONS}
query Transactions {
  p_transactions(
   where: {_and: [ 
      {_or: [
        {type_transaction: {_eq: "OUT"}},
        {type_transaction: {_is_null: true}},
      ]},
     {amount: {_lt: 0}}
   ]},
    order_by: {created_at: desc}
  ){
    ...p_transactions_fields
  }
}
`;

export default TransactionsTable;
