import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";
//import { isAuth } from "./auth/authHelper.js";
import { pretty_print_token, /*setUserTenant,*/ getUserTenant } from "./auth/authHelper";

import history from './history.js';

import {
  //BrowserRouter as Router,
  Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import LoginPage from "./components/LoginPage";
import LogoutPage from "./components/LogoutPage";
import PageNotFound from "./components/PageNotFound";
import PublicPage from "./components/PublicPage";
import AboutPage from "./components/AboutPage";

import PlanningPage from "./components/planning";
import CompaniesPage from "./components/companies";
import TrucksPage from "./components/trucks";
import UsersPage from "./components/users";
import OrdersPage from "./components/orders";
import ShipmentsPage from "./components/shipments";
import AccountsPage from "./components/finance/Accounts.js";

import TransactionsPage from "./components/finance/Transactions.js";
import TransactionsIncPage from "./components/finance/TransactionsInc.js";
import TransactionsOutPage from "./components/finance/TransactionsOut.js";
import TransactionsDebugPage from "./components/finance/TransactionsDebug.js";

import TransactionsConfigPage from "./components/finance/TransactionsConfigPage.js";
import ExchangeConfigPage from "./components/finance/ExchangeConfigPage.js";
import ChartsPage from "./components/charts/ChartsPage.js";

import GocardlessPage from "./components/finance/gocardless/GocardlessPage.js";
import GocardlessRedirect from "./components/finance/gocardless/GocardlessRedirect.js";

import RaiffeisenPage from "./components/finance/raiffeisen/RaiffeisenPage.js";
import RaiffeisenRedirect from "./components/finance/raiffeisen/RaiffeisenRedirect.js";

import Settings from "./components/settings";
import TestPage from "./components/test/TestPage";
import { AuthNavBar } from "./components/nav/AuthNavBar";
import { NavBar } from "./components/nav/NavBar";

import { AUTH_TOKEN } from "./constant";
import { Layout } from "antd";
import { FormContainer } from "./FormContainer";

const { Header, Content } = Layout;

const ProtectedRoute = ({ component: Component, token, ...rest }) => {
  console.log("ProtectedRoute: begin, path="+rest.path+", token="+pretty_print_token(token));
  return token ? (
    <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
  ) : (
    //<Route {...rest} render={(matchProps) => <LoginPage token="" {...matchProps} />} />
    <Redirect to="/login" />
  );
};

const RootContainer = (props) => {
  const [ token, setToken ] = useState(localStorage.getItem(AUTH_TOKEN));
  const { auth, tab, dispatch } = useStoreon("auth", "tab");
 
  useEffect(() => {
    const tenant = getUserTenant();
    console.log("RootContainer.useEffect: MOUNT, tenant tab:["+tab?.tenant+"], persist:["+tenant+']');
    // overhead? @init
    if (!tab?.tenant || !tenant) {
      // set default's
      dispatch("setTabData", { tenant: process.env.REACT_APP_DEFAULT_TENANT});
      // setUserTenant(process.env.REACT_APP_DEFAULT_TENANT);
      console.log("RootContainer.useEffect: MOUNT, set default tenant: ", process.env.REACT_APP_DEFAULT_TENANT);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN);
    console.log("RootContainer.useEffect [i]: begin token="+pretty_print_token(token)+", auth="+auth.authenticated);
    if (auth.authenticated && token !== null && token !== undefined) {
      console.log("RootContainer.useEffect [i]: call setToken()");
      setToken(token);
    } else {
      console.log("RootContainer.useEffect [i]: clear storage");
      localStorage.clear();
      sessionStorage.clear();
      console.log("RootContainer.useEffect [i]: clear token");
      setToken(null);
      // dispatch?
      //auth.authenticated = false;
      if (auth.authenticated) dispatch("setAuthData", { authenticated: false});
      if (props.client) props.client.clearStore().then(() => {
              console.log("ErrorLink.onError: clear cache");
              //props.client.resetStore();
           });
    }
    console.log("RootContainer.useEffect [i]: end token="+pretty_print_token(token)+", auth="+auth.authenticated);
  }, [auth, history]);

  return (
    <Router history={history}>
      <Switch>
      <ProtectedRoute token={token} path="/finance/raiffeisen/redirect" component={RaiffeisenRedirect} />
      <ProtectedRoute token={token} path="/finance/gocardless/redirect" component={GocardlessRedirect} />
      <Layout style={{ height: "100vh" }}>
        <Header
          style={{
            height: "44px",
            position: "fixed",
            zIndex: 1,
            width: "100%",
            padding: "0 5px",
          }}
        >
          {auth.authenticated ? <AuthNavBar /> : <NavBar />}
        </Header>
        <Content style={{ margin: "10px", marginTop: 46 }}>
          <Switch>
            <Route exact path="/" component={PublicPage} />
            <Route path="/about" component={AboutPage} />
            <ProtectedRoute
              token={token}
              path="/planning"
              component={PlanningPage}
            />
            <ProtectedRoute
              token={token}
              path="/companies"
              component={CompaniesPage}
            />
            <ProtectedRoute
              token={token}
              path="/trucks"
              component={TrucksPage}
            />
            <ProtectedRoute token={token} path="/users" component={UsersPage} />
            <ProtectedRoute token={token} path="/orders" component={OrdersPage} />
            <ProtectedRoute token={token} path="/shipments" component={ShipmentsPage} />
            <ProtectedRoute token={token} path="/finance/accounts" component={AccountsPage} />

            <ProtectedRoute token={token} path="/finance/transactions" component={TransactionsPage} />
            <ProtectedRoute token={token} path="/finance/transactions_inc" component={TransactionsIncPage} />
            <ProtectedRoute token={token} path="/finance/transactions_out" component={TransactionsOutPage} />
            <ProtectedRoute token={token} path="/finance/transactions_debug" component={TransactionsDebugPage} />

            <ProtectedRoute token={token} path="/finance/transactions_config" component={TransactionsConfigPage} />
            <ProtectedRoute token={token} path="/finance/exchange_config" component={ExchangeConfigPage} />
            <ProtectedRoute token={token} path="/finance/charts" component={ChartsPage} />

            <ProtectedRoute token={token} path="/finance/gocardless" component={GocardlessPage} />
            <ProtectedRoute token={token} path="/finance/raiffeisen" component={RaiffeisenPage} />

            <ProtectedRoute
              token={token}
              path="/settings"
              component={Settings}
            />
            <ProtectedRoute token={token} path="/test" component={TestPage} />

            <Route
              token={token}
              path="/login"
              render={(props) => <LoginPage {...props} />}
            />
            <Route path="/logout" component={LogoutPage} />
            <Route component={PageNotFound} />
          </Switch>
        </Content>
        {auth.authenticated && <FormContainer />}
      </Layout>
      </Switch>
    </Router>
  );
};

export default RootContainer;
